
import { defineComponent, ref } from 'vue'

export default defineComponent({
    emits: ['createWhatComment'],
    props: { 
        placeholder: {
            type: String,
            default: null
        }
    },
    setup(props: any,  {emit}) {
        const comment = ref('');

        const createWhatComment = ()=>{
            emit('createWhatComment', comment.value);
            comment.value = '';
        }


        return{
            comment,
            createWhatComment
        }
    },
})

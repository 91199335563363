
import {
    defineComponent,
    ref,
    inject,
    computed,
    onBeforeMount,
    reactive,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PaginationWat from '@/components/PaginationWat.vue'
import CommentWhat from '@/components/CommentWhat.vue'
import CommentWhatLoading from '@/components/CommentWhatLoading.vue'
import CommentInputWhat from '@/components/CommentInputWhat.vue'

export default defineComponent({
    components: {
        PaginationWat,
        CommentWhat,
        CommentWhatLoading,
        CommentInputWhat,
    },
    setup() {
        const whatStore: any = inject('whatStore')

        const pageSize = ref(10)
        const commentsPage = ref(1)

        const title = ref('')
        const backgroundImage = ref('')
        const post: any = ref({})
        const comments: any = ref([])
        const postContentText = ref('')
        const totalComments = computed(() => {
            const numericCount = Number(whatStore.state.whatState.commentsCount[params.id]);
            return numericCount;
        })
        const comment = ref('')

        const route: any = useRoute()
        const router: any = useRouter()

        const params: any = reactive(route.params)

        const goToPostsList = () => {
            router.push({ name: 'what', replace: true })
        }
        const fetchCommentsCount = async () => {
            await whatStore.getWhatPostsCommentsCount({ postIds: [params.id] })
        }
        const fetchComments = async () => {
            comments.value = await whatStore.getWhatPostComments(
                params.id,
                commentsPage.value - 1,
                pageSize.value
            )
            if (!comments.value) {
                console.log('error')
            }
        }
        const createWhatComment = async () => {
            const data = {
                comment: comment.value
                    .replace(/(<([^>]+)>)/gi, '')
                    .replace(/(&.*;)/, ''),
            }
            await whatStore.createWhatComment(params.id, data)
            fetchComments()
            comment.value = ''
        }
        const rateCommentPositive = async (commentId: any) => {
            await whatStore.rateWhatCommentPositive(commentId)
            fetchComments()
        }
        const rateCommentNegative = async (commentId: any) => {
            await whatStore.rateWhatCommentNegative(commentId)
            fetchComments()
        }
        const replyToComment = async (parentCommentId: any, comment: any) => {
            const data = {
                comment: comment
                    .replace(/(<([^>]+)>)/gi, '')
                    .replace(/(&.*;)/, ''),
                answerTo: parentCommentId,
            }
            await whatStore.replyToComment(params.id, data)
            fetchComments()
        }

        onBeforeMount(async () => {
            const data = await whatStore.getWhatPost(params.id)
            post.value = data
            title.value = await post.value.title.rendered
            backgroundImage.value =
                (await post.value.better_featured_image.media_details.sizes
                    .medium_large.source_url) ||
                post.value.better_featured_image.media_details.sizes.large
                    .source_url
            postContentText.value = await post.value.content.rendered
            await fetchCommentsCount()
            await fetchComments()
        })
        return {
            goToPostsList,
            createWhatComment,
            rateCommentPositive,
            rateCommentNegative,
            replyToComment,
            fetchComments,
            totalComments,
            title,
            backgroundImage,
            comments,
            commentsPage,
            postContentText,
            comment,
            whatStore
        }
    },
})
